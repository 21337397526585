<template>
  <div>
    <div class="operationControl">
      <div class="searchbox">
        <div title="课程名称" class="searchboxItem ci-full">
          <span class="itemLabel">课程名称:</span>
          <el-input v-model="searchName" type="text" clearable size="small" placeholder="请输入课程名称" />
        </div>
        <div title="课程类型" class="searchboxItem">
          <span class="itemLabel">课程类型:</span>
          <el-cascader
              clearable
              filterable
              v-model="courseType"
              :options="miniCourseTypeList"
              :props="miniProps"
              size="small"
          ></el-cascader>
        </div>
        <div class="df">
          <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
          :header-cell-style="tableHeader"
        >
          <el-table-column label="序号" align="center" type="index" fixed :index="indexMethod"/>
          <el-table-column
            label="课程名称"
            width="240"
            align="left"
            show-overflow-tooltip
            prop="courseName"
            fixed 
          />
          <el-table-column label="课程分类" prop="courseTypeName" show-overflow-tooltip align="right" />
          <el-table-column label="课件数量" prop="coursewareNum" show-overflow-tooltip align="right" />
          <el-table-column
              label="课程总时长"
              minWidth="120"
              prop="courseTotalDuration"
              show-overflow-tooltip
              align="right"
          >
            <template slot-scope="scope">
              <span>{{scope.row.courseTotalDuration}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="140px" fixed="right">
            <div slot-scope="scope" class="flexcc">
              <el-button
                type="text"
                style="padding:0 5px"
                size="mini"
                @click="seeCourse(scope.row)"
              >详情</el-button>
              <el-button
                style="padding:0 5px"
                type="text"
                size="mini"
                :disabled="!scope.row.addState"
                @click="salesState(scope.row.courseId)"
              >加入我的课程</el-button>
            </div>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "platform_Course",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      CompanyList: [], // 资源提供者
      activeName: "first",
      searchName: "",
      courseType: null,
      params: {},
      
      propsarea: {
        value: "courseTypeId",
        label: "name",
        emitPath: false,
        checkStrictly: true,
      },
      miniCourseTypeList:[],
       miniProps: {
        label: "name",
        value: "courseTypeId",
        checkStrictly: false,
        emitPath: false,
      },
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.getcourseTypeArrData();
  },
  methods: {
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },

    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        courseName: this.searchName || "",
        courseType: this.courseType
      };
      this.doFetch({
        url: "/minicourse/publicCoursePageList",
        params,
        pageNum
      }, true, 2);
    },
    seeCourse(row) {
      this.$router.push({
        path: "/web/miniApp/platform/courseDetail",
        query: {
          courseId: row.courseId,
          addState:row.addState
        }
      });
    },
    // 资源提供者
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 加入
    salesState(courseId) {
      this.$post("/minicourse/addPlatformCourse", {
        courseId: courseId
      }, 3000, true, 2)
        .then(res => {
          if (res.status == 0) {
            this.$message({
              type: "success",
              message: "已成功加入我的课程"
            });
            this.getData(-1);
          }
        })
        .catch(() => {
          return;
        });
    },
         //获取课程分类数组数据
    getcourseTypeArrData() {
      this.$post(
        "miniapp/coursetype/selectTree",
        { hiddenState: "10" },
        3000,
        true,
        2
      ).then((ret) => {
        this.miniCourseTypeList = this.delchild(ret.data);
      });
    },
     delchild(list = []) {
      for (let i = 0; i < list.length; i++) {
        const el = list[i];
        if (el.children.length == 0) {
          delete el.children;
        }
        this.delchild(el.children);
      }
      return list;
    },
    // getareatree() {
    //   this.$post("/miniapp/coursetype/selectTree", {},
    //       3000, true, 2).then((ret) => {
    //         console.log(ret);
    //     this.areatreeList = ret.data.list;
    //   });
    // },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40 + 15;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    }
  },
};
</script>
<style lang="less">
.addlist {
  h3 {
    padding: 10px 0;
  }
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 40px;
  }
}
.certificate-box {
  > div:last-child {
    border: 0;
  }
}
.card-border {
  padding: 10px;
  border-bottom: 1px dashed #eee;
}
</style>
